<template>
  <div class="property-fee-detail">
    <div class="content">
      <div class="title">
        <van-icon name="location-o" />{{ getLocation(obj) }}
      </div>
      <van-cell-group class="cell-group">
        <van-cell title="房屋面积">
          <template #right-icon>
            <span class="address"
              >{{ Number(obj.roomAmountArea).toFixed(2) }}m²</span
            >
          </template>
        </van-cell>
        <van-cell title="单位金额">
          <template #right-icon>
            <span class="price-month"
              >{{ Number(obj.unitPrice).toFixed(2) }}元/㎡/月</span
            >
          </template>
        </van-cell>
        <van-cell title="缴费周期">
          <template #right-icon>
            <span class="price-time"
              >{{ formatDate(obj.startTime) }}~{{
                formatDate(obj.endTime)
              }}</span
            >
          </template>
        </van-cell>
        <van-cell title="缴费金额">
          <template #right-icon>
            <span class="price">{{ Number(obj.totalCost).toFixed(2) }}元</span>
          </template>
        </van-cell>
      </van-cell-group>
      <div class="btns">
        <div @click="payment(obj.totalCost)" class="take-money">缴费</div>
        <div @click="goBack" class="back">返回</div>
        <div @click="showDetail" class="show-price">查看房屋缴费记录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPayDetail } from "@/api/propertyApi";
import { pay } from "@/api/propertyApi";
import { Notify } from "vant";
export default {
  name: "PropertyFeeDetail",
  data() {
    return {
      obj: {},
    };
  },
  created() {
    this.getFeeDetail();
  },
  computed: {
    // 获取住址
    getLocation() {
      return (obj) => {
        return `${obj.estateName}${obj.buildingName}${obj.unitName}${obj.roomName}`;
      };
    },
    // 格式化时间
    formatDate() {
      return (date) => {
        if (date) {
          let arr = date.split("-");
          return `${arr[0]}年${arr[1]}月`;
        }
      };
    },
  },
  methods: {
    // 获取详情
    async getFeeDetail() {
      const { id } = this.$route.query;
      const res = await getPayDetail({
        id,
      });

      if (res.code == 200) {
        this.obj = res.data;
      }
    },
    // 缴纳
    async payment(totalCost) {
      const { id } = this.$route.query;
      if (this.isWeiXin()) {
        this.$store.commit("SET_IS_LOADING", true);
        const res = await pay(
          {
            acct: JSON.parse(localStorage.getItem("yt-openId")).content, // 用户openid
            paytype: "W01", // 交易方式
            body: "", // 主题内容
            remark: "", // 备注
          },
          {
            id,
            totalCost,
            paymentStatus: 0, // 缴费状态
          }
        );
        this.$store.commit("SET_IS_LOADING", false);

        if (res.code == 200) {
          let data = res.data;
          const { payinfo } = data;
          if (!payinfo) {
            return this.$toast({
              type: "fail",
              message: data.errmsg ? data.errmsg : "支付失败",
              duration: 1000,
            });
          }
          window.location.href = payinfo;
          window.location.href = payinfo;
          // let payinfoObj = JSON.parse(payinfo);
          // const {
          //     appId,
          //     timeStamp,
          //     nonceStr,
          //     package: pac,
          //     signType,
          //     paySign,
          // } = payinfoObj;

          // wx.config({
          //     debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          //     appId: appId, // 必填，公众号的唯一标识
          //     timestamp: timeStamp, // 必填，生成签名的时间戳
          //     nonceStr: nonceStr, // 必填，生成签名的随机串
          //     signature: 'asda',// 必填，签名
          //     jsApiList: ['chooseWXPay'] // 必填，需要使用的 JS 接口列表
          // });

          // wx.chooseWXPay({
          //     appId: appId,
          //     timestamp: timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
          //     nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
          //     package: pac, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          //     signType: signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          //     paySign: paySign, // 支付签名
          //     success: function (res) {
          //         // 支付成功后的回调函数
          //         console.log(res);
          //         // 支付成功跳转成功页面
          //         this.$router.replace({
          //             path: '/paySuccess'
          //         });
          //     }
          // });
        }
      } else {
        // 警告通知
        Notify({ type: "warning", message: "请使用微信浏览器打开页面" });
      }
    },
    // 查看详情
    showDetail() {
      const { roomId } = this.obj;
      this.$router.push("/paymentRecords?roomId=" + roomId);
    },
    // 返回
    goBack() {
      this.$router.back();
    },
    // 判断是否是微信浏览器
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();

      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.property-fee-detail {
  .content {
    padding: 10px;
    background-color: #fff;

    .title {
      font-size: 18px;
      padding-left: 10px;
    }

    .cell-group {
      margin-top: 10px;

      .address {
      }

      .price-month {
      }

      .price-time {
      }

      .price {
        color: #fb001e;
      }
    }

    .btns {
      margin-top: 100px;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;

      .take-money {
        width: 250px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        background-color: #409eff;
        color: #fff;
      }

      .back {
        width: 250px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        background-color: #909399;
        color: #fff;
        margin-top: 10px;
      }

      .show-price {
        width: 250px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        // background-color: #409eff;
        color: #7e7474;
        margin-top: 10px;
      }
    }
  }
}
</style>